<template>
    <div class='article-container'>
        <div class="contents">
            <div class="title-group">
                <span class="title">{{ props.item.title }}</span>
                <span class="text">{{ props.item.announce }}</span>
            </div>
            <div class="info">
                <span v-if="false">{{ useDateFormat(props.item.created_at, 'DD.MM.YYYY hh:mm') }}</span>
                <NuxtLinkLocale :to="`/blog/${props.item.slug}`" class="btn btn-light">{{ $t('article.read') }}</NuxtLinkLocale>
            </div>
        </div>
        <div class="article-container__image-block">
          <NuxtImgC width="318px" height="419px" :src="articleImage || props.item.image_id" sizes="550px lg:318px" />
        </div>
    </div>
</template>
<script lang='ts' setup>
import type { BlogItem } from '~/composables/articles/types/ArticlesTypes';
interface IProps {
    item: BlogItem
}
const props = defineProps<IProps>();
const { locale } = useI18n()
const { $viewport } = useNuxtApp()
const articleImage = computed(() => {
  return $viewport.isLessThan('md') ? props.item?.mobile_banner_id : props.item?.image_id
})
</script>

<style lang="scss" scoped>

div {
    box-sizing: border-box;
}

.article-container {
    display: flex;
    overflow: hidden;
    border-radius: 32px;
    box-shadow: 0px 4px 6px 0px #3956D91A;
}
.article-container__image-block {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-width: 318px;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &:after {
      content: "";
      float: left;
      padding-top: 40%;
    }
  }
}

.contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    border-left: 1px solid #3956D91A;
    border-top: 1px solid #3956D91A;
    border-bottom: 1px solid #3956D91A;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    padding: 26px;
}

.title-group {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title {
        font-size: 21px;
        font-weight: 800;
        line-height: 107%;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 113%;
        -webkit-line-clamp: 8;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @supports not (--webkit-line-clamp: 8) {
            overflow: hidden;
            height: calc(18px * 8)
        }
    }
}

.info {
    display: flex;
    flex-direction: column;
    gap: 14px;

    span {
        color: #DA4727;
        font-size: 15px;
        font-weight: 600;
        line-height: 140%;
    }
}

.btn {
    text-align: center;
    font-weight: 600;
    width: 100%;
}

@media (max-width:1023px) {
    .article-container {
        flex-direction: column-reverse;
        border-radius: 24px;
    }

    .article-container__image-block {
      min-height: 181px;
    }

    .contents {
        border-top-left-radius: 0;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        border-left: 1px solid #3956D91A;
        border-right: 1px solid #3956D91A;
        border-top: none;
        border-bottom: 1px solid #3956D91A;
        padding: 16px;
        gap: 8px;
    }

    .title-group {
        gap: 8px;

        .title {
            font-size: 19px;
        }

        .text {
            font-size: 17px;
        }
    }

}
</style>
