import type { ArticleResponce, ArticleRequest, BlogItem, ArticleByIDResponse } from './types/ArticlesTypes';

export function useArticles() {
    const { $api } = useNuxtApp();

    const getArticles = async (options: ArticleRequest) => {
        const res = await $api<ArticleResponce>('/posts/all', {
            method: 'GET',
            params: {
                ...options
            }
        });

        if (res) {
            return res.posts;
        }
        return null;
    }

    const getArticleById = async (slug: string): Promise<ArticleByIDResponse | null> => {
        const res = await $api<ArticleByIDResponse>(`/posts/slug/${slug}`, {
            method: 'GET'
        });

        if (res) {
            return res;
        }
        return null;
    }

    return {
        getArticles,
        getArticleById
    }
}
